import React from 'react';

import { Tag, Tooltip } from 'antd';

import './colorTag.less';

export default function ColorTag({ item, closable = false, onClick, onClose, extraClasses }) {
	const { name, color, nameToShow, hoverText } = item || {};
	if (name === 'Orientation Scheduled') {
		console.log(item);
	}

	return (
		<Tooltip color={color} rootClassName="tooltip-style-background br-40" placement="right" title={hoverText}>
			<Tag
				closable={closable}
				{...(onClose && { onClose })}
				key={`item=${item?._id}`}
				onClick={onClick}
				style={{ backgroundColor: color, borderColor: color }}
				className={`tag-style tag-color ${extraClasses} br-40`}>
				<>{nameToShow || name}</>
			</Tag>
		</Tooltip>
	);
}
