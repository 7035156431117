import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import request from '../../../utils/API/apiHandler';

const initialState = {
	logsHistory: [],
	allLogsHistory: [],
	ticketLogsHistory: [],
	loading: false,
	error: false,
	canLoadMore: false,
	selectedStage: 'All Stages',
	startDate: null,
	endDate: null,
	page: 0,
	pageSize: 10,
	role: '',
};

export const getTicketLogsHistory = createAsyncThunk('getTicketLogsHistory', async (params, { getState }) => {
	const { ticket } = getState().ticketDetailsReducer;
	const { user } = getState().auth;

	const responseData = await request({ url: `/tickets/ats/ticket-log-history/${ticket._id}`, notify: true });
	const role = user?.role;
	return { responseData, role };
});

const logHistory = createSlice({
	name: 'logsHistory',
	initialState,
	reducers: {
		loadMore: (state, { payload }) => {
			state.loading = true;
			state.page = state.page + 1;
		},
		setSelectedStage: (state, { payload }) => {
			state.selectedStage = payload;
			loadHistoriesFilters(state);
		},
		setDateRange: (state, { payload }) => {
			const { startDate, endDate } = payload;
			state.startDate = startDate;
			state.endDate = endDate;
			loadHistoriesFilters(state);
		},
		resetAll: () => initialState,
		resetData: (state, { payload }) => {
			state.logsHistory = [];
			state.page = 0;
			state.loading = false;
			state.error = false;
			state.canLoadMore = false;
		},
		loadMoreLocalData: state => {
			const start = state.page * state.pageSize;
			state.canLoadMore = start + state.pageSize < state.allLogsHistory.length;
			state.logsHistory.push(...state.allLogsHistory.slice(start, start + state.pageSize));
		},
	},
	extraReducers: {
		[getTicketLogsHistory.fulfilled]: (state, { payload }) => {
			const { responseData = null, role } = payload;

			let histories = responseData?.histories?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) || [];

			state.ticketLogsHistory = { ...responseData, histories };
			state.role = role;

			loadHistoriesFilters(state);
		},
		[getTicketLogsHistory.pending]: state => {
			state.loading = true;
			state.progressive = null;
		},
	},
});

function loadHistoriesFilters(state) {
	// apply other filters
	state.allLogsHistory = state.ticketLogsHistory.histories.filter(item => {
		// filter is developer
		if (item.role === 'developer' && state.role !== 'developer') {
			return false;
		}

		// stages filter
		if (state.selectedStage !== 'All Stages' && state.selectedStage !== item.stage) {
			return false;
		}

		// date filters
		if (state.startDate && state.endDate) {
			return item.createdAt >= state.startDate && item.createdAt <= state.endDate;
		}

		if (state.startDate) {
			return item.createdAt >= state.startDate;
		}

		if (state.endDate) {
			return item.createdAt <= state.endDate;
		}

		return true;
	});

	state.error = false;
	state.loading = false;

	state.canLoadMore = state.page + state.pageSize < state.allLogsHistory.length;
	state.logsHistory = state.allLogsHistory.slice(state.page, state.page + state.pageSize);
}

export const { reducer, actions, state } = logHistory;
