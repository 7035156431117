import React from 'react';
import dayjs from '../../utils/dateHandler';
import { formatPhoneNumber, getProp } from '../../utils/functions';
import { Link } from 'react-router-dom';
import { Progress, Tag, Tooltip, Button } from 'antd';
import { PhoneOutlined, DownloadOutlined } from '@ant-design/icons';
import { push } from 'connected-react-router';
import { capitalize } from 'lodash';
import { store } from '../../redux/store';
// import { handleCall } from '../communication/call/call';
import { DATE_FORMAT } from '../../utils/time';
import { getLanguageName } from '../../utils/languages';
import { accessFile } from '../../utils/accessFile';
import ExpiringDocumentsModal from '../../pages/TicketDetail/SideBar/components/ExpiringDocuments/ExpiringDocumentsModal/ExpiringDocumentsModal';
import MissingDocumentsModal from '../../pages/Department/Components/MissingDocumentsModalATS/MissingDocumentsModal.js';
import OrientationModal from '../../pages/Department/Components/OrientationModal/OrientationModal';
import DeniedStagesModal from '../../pages/Department/Components/DeniedStagesATS/DeniedStagesModal';
import ColorTag from '../../shared/components/TagColor/ColorTag.jsx';

const getSiteData = (obj, siteName) => {
	const stages = obj?.record?.stages;
	const bgCheckObj = stages?.find(stage => stage.type === 'BGCheck');
	return bgCheckObj?.sites?.find(site => site.name === siteName);
};

const parseString = value => {
	const dateRegExp = /^\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}(:\d{2}(\.\d{1,3})?)?)?(Z)?$/;
	return dateRegExp.test(value) ? dataParser.dateAbsolute({ value }) : value.toString();
};

const parseCapString = value => {
	const str = parseString(value);
	return str[0].toUpperCase() + str.slice(1);
};

const dataParser = {
	text: obj => (obj.value ? parseString(obj.value) : 'No Data'),

	textCapitalize: obj => (obj.value ? parseCapString(obj.value) : 'No Data'),

	link: obj => <Link to={`/${obj.departmentName}/ticket/${obj.record._id}`}>{obj.value}</Link>,
	viewed: obj => {
		if (obj.value) {
			return 'Viewed';
		} else {
			return <Link to={`/${obj.departmentName}/ticket/${obj.record._id}`}>{obj.departmentName === 'coordination' ? 'See Shift' : 'See Ticket'}</Link>;
		}
	},

	status: obj => {
		const value = obj?.value;

		if (['caregiver', 'patient'].includes(obj?.departmentName) && typeof value === 'string') {
			return capitalize(obj.value);
		}
		return typeof value !== 'object' ? 'None' : <ColorTag item={value} />;
	},
	tags: obj => {
		obj.value = obj?.value?.filter(tag => tag !== null && typeof tag === 'object') || [];

		if (!obj.value.length) {
			return 'None';
		}

		const tag = obj.value[0];
		const tags = obj.value.slice(1);

		if (tags.length) {
			return (
				<>
					<ColorTag item={tag} />

					<Tooltip
						color="#fafafa"
						title={() =>
							tags.map((t, i) => {
								return <ColorTag item={t} key={i} />;
							})
						}
					>
						<Tag>+{tags.length}</Tag>
					</Tooltip>
				</>
			);
		}

		return <ColorTag item={tag} />;
	},

	'date-relative': obj => dayjs(obj.value).fromNow(),

	dateAbsolute: obj => (obj.value ? dayjs(obj.value).format(DATE_FORMAT) : 'No Data'),

	dateCustomFormat: obj => (obj.value ? dayjs(obj.value).format('MM/DD/YY hh:mm A') : 'No'),

	phone: ({ record, departmentName, value }) => (
		<a
			onClick={() => {
				store.dispatch(push(`/${departmentName}/ticket/${record._id}`));

				// const title = getCallTitle(record);

				// handleCall({ title, departmentName, phone: value, ticket: record });
			}}
			style={{ color: '#1890ff', cursor: 'pointer', display: 'block' }}
		>
			{formatPhoneNumber(value) || 'No Data'}
			<PhoneOutlined style={{ marginLeft: '10px' }} />
		</a>
	),

	language: obj => (obj?.value?.length <= 3 ? getLanguageName(obj.value) : obj.value),

	progressBar: obj => {
		const percent = Math.round(obj.value);
		return <Progress size={[100, 8]} percent={percent} className="progressbar" strokeColor={percent === 100 ? '#90CC84' : '#a6695a91'} />;
	},

	atsProgressBar: obj => {
		const stages = obj?.record?.stages?.filter(stage => stage.name !== 'Verifications');
		const stagesCompleted = stages?.filter(stage => stage.state === 'completed');
		const percent = stages?.length ? Math.round((stagesCompleted.length * 100) / stages.length) : 0;

		return <Progress size={[100, 8]} percent={percent} className="progressbar" strokeColor={percent === 100 ? '#90CC84' : '#a6695a91'} />;
	},

	documents: obj => <ExpiringDocumentsModal documents={obj.value} title={obj.record.title} id={obj.record.ticketID} />,

	documentsCompleted: obj => (
		<MissingDocumentsModal record={obj.record} applicationID={obj?.record?.ticketID} fullName={obj?.record?.applicant?.fullName} id={obj?.record?._id} />
	),

	documentsLocation: obj => {
		const stages = obj?.record?.stages;
		const documentsObj = stages?.find(stage => stage.name === 'Documents');
		return documentsObj?.['location'];
	},

	applicationCompleted: obj => {
		const stages = obj?.record?.stages;
		const applicationObj = stages?.find(stage => stage?.name === 'Application');
		return applicationObj?.state === 'completed' ? 'Yes' : 'No';
	},

	i9Verified: obj => {
		const stages = obj?.record?.stages;

		const i9Stage = stages?.find(stage => stage.name === 'I9');

		return i9Stage?.state === 'completed' ? 'Yes' : 'No';
	},

	yesNo: obj => (obj.value ? 'Yes' : 'No'),

	valueEqualsYes: obj => (obj.value === 'Yes' ? 'Yes' : 'No'),

	orientation: obj => {
		const orientationStage = obj?.record?.stages?.find(stage => stage?.name === 'Orientation');
		return <OrientationModal orientationStage={orientationStage} />;
	},

	reponseNumber: obj => {
		if (obj.record.numberOfResponses === undefined || obj.record.numberOfResponses === null) {
			return 'No Data';
		}

		return `${obj.record.numberOfResponses} ${obj.record.numberOfResponses === 1 ? 'Time' : 'Times'}`;
	},

	lastResponse: obj => {
		if (!obj.record.numberOfResponses) return 'No Data';
		return dayjs(obj.record.lastResponded).fromNow();
	},

	bgCheckSubmitted: obj => {
		const siteName = obj?.record?.BGStage?.sites?.name;
		const site = getSiteData(obj, siteName);
		return site?.result?.startDate ? dayjs(site?.result?.startDate).format('MM/DD/YY') : false;
	},

	documentsExpirationDate: obj => {
		const documents = obj?.value;
		const maxDate = documents
			?.map(doc => doc?.expirationDate)
			.reduce((min, current) => (new Date(current) < new Date(min) ? current : min), documents[0]?.expirationDate);
		return dayjs(maxDate).format('MM/DD/YY');
	},

	bgCheckLastUpdate: obj => {
		const siteName = obj?.record?.BGStage?.sites?.name;
		const site = getSiteData(obj, siteName);
		return site?.result?.lastUpdate ? dayjs(site?.result?.lastUpdate).format('MM/DD/YY') : false;
	},

	bgCheckLastCheck: obj => {
		const siteName = obj?.record?.BGStage?.sites?.name;
		const site = getSiteData(obj, siteName);
		return site?.result?.lastCheck ? dayjs(site?.result?.lastCheck).format('MM/DD/YY') : false;
	},

	bgCheckStatus: obj => {
		const siteName = obj?.record?.BGStage?.sites?.name;
		const site = getSiteData(obj, siteName);
		return site?.result?.status;
	},

	bgCheckDocument: obj => {
		const files = obj?.record?.files;
		if (!files) return;
		let file = files.find(el => el.name.includes('schedulebgc'));
		if (!file) file = files.find(el => el.name.toLowerCase().includes('chrc'));
		return file ? (
			<Tooltip title="Download" key="download">
				<Button
					type="link"
					size="small"
					onClick={() => {
						accessFile(obj?.record?._id, file?.name);
					}}
				>
					<DownloadOutlined />
				</Button>
			</Tooltip>
		) : (
			false
		);
	},
	stagesDenied: obj => (
		<DeniedStagesModal record={obj.record} applicationID={obj?.record?.ticketID} fullName={obj?.record?.applicant?.fullName} id={obj?.record?._id} />
	),

	documentUploaded: obj => {
		const files = obj?.record?.files;
		const hasUploadedFiles = files?.filter(file => file?.fromUploaderTool);
		return hasUploadedFiles?.length ? 'Yes' : 'No';
	},
	tbScreen: obj => {
		const stages = obj?.record?.stages;
		const documentsObj = stages?.find(stage => stage.name === 'Documents')?.answers;
		if (!documentsObj?.length) return 'No';
		const answerValue = documentsObj.find(ans => ans?.questionName === 'Tuberculosis (TB) Test')?.value;
		return answerValue ? answerValue : 'No';
	},
	competency: obj => {
		const stages = obj?.record?.stages;
		const documentsObj = stages?.find(stage => stage.name === 'Documents')?.answers;
		if (!documentsObj?.length) return 'No';
		const answerValue = documentsObj.find(ans => ans?.questionName === 'Competency')?.value;
		return answerValue ? answerValue : 'No';
	},
	orientationDate: obj => {
		const orientationStageDate = obj?.record?.stages?.find(stage => stage?.name === 'Orientation')?.date;
		return orientationStageDate ? dayjs(orientationStageDate).format(DATE_FORMAT) : 'No Data';
	},
	otherSource: obj => {
		return obj?.record?.otherSource || obj?.record?.patientName || 'No Data';
	},
	studentCode: obj => {
		return obj?.record?.student?.code || obj?.record?.student?.customCode || 'No Data';
	},
};

const parserMapper = {
	ticketID: dataParser.link,
	title: dataParser.link,
	fullName: dataParser.link,
	entityID: dataParser.link,
	viewed: dataParser.viewed,
	status: dataParser.status,
	tags: dataParser.tags,
	updatedAt: dataParser.dateAbsolute,
	createdAt: dataParser.dateAbsolute,
	primaryPhone: dataParser.phone,
	progress: dataParser.progressBar,
	atsProgress: dataParser.atsProgressBar,
	language: dataParser.language,
	lastLogin: dataParser.dateAbsolute,
	applied: dataParser.yesNo,
	documents: dataParser.documents,
	documentsLocation: dataParser.documentsLocation,
	documentsCompleted: dataParser.documentsCompleted,
	documentsExpirationDate: dataParser.documentsExpirationDate,
	i9Verified: dataParser.i9Verified,
	applicationCompleted: dataParser.applicationCompleted,
	orientation: dataParser.orientation,
	hireDate: dataParser.dateAbsolute,
	hireDateTicket: dataParser.dateAbsolute,
	expirationDate: dataParser.dateAbsolute,
	bgCheckSubmitted: dataParser.bgCheckSubmitted,
	bgCheckLastUpdate: dataParser.bgCheckLastUpdate,
	bgCheckLastCheck: dataParser.bgCheckLastCheck,
	bgCheckStatus: dataParser.bgCheckStatus,
	bgCheckDocument: dataParser.bgCheckDocument,
	stagesDenied: dataParser.stagesDenied,
	documentUploaded: dataParser.documentUploaded,
	orientationDate: dataParser.orientationDate,
	competency: dataParser.competency,
	tbScreen: dataParser.tbScreen,
	valueEqualsYes: dataParser.valueEqualsYes,
	textCapitalize: dataParser.textCapitalize,
	otherSource: dataParser.otherSource,
	studentCode: dataParser.studentCode,
};

function renderData(data, { path, parserName, departmentName }) {
	const parserSplit = path.split(':');
	const pathArr = path.split('.');
	const fieldName = parserSplit[1] || pathArr[pathArr.length - 1];

	const parser = parserName ? dataParser[parserName] : parserMapper[fieldName] || dataParser.text;

	let value = getValueFromObject(data, parserSplit[0]) || getProp(data, path);

	if ((departmentName === 'recruitment' && path === 'interviewDate') || (departmentName === 'hhau' && path === 'progression.completedAt')) {
		value = value && value !== 'No Data' ? dayjs(value).format(DATE_FORMAT) : value;
	}
	if (departmentName === 'recruitment' && path === 'source') {
		value =
			value === 'facebook' || value === 'zip' || value === 'indeed' || value === 'instagram' || value === 'outbound'
				? `${value.charAt(0).toUpperCase()}${value.slice(1)}`
				: value;
	}

	if (departmentName === 'payroll' && path === 'company' && value === 'aj') {
		value = 'A&J';
	}

	if (departmentName === 'payroll' && path === 'company' && value === 'hcs') {
		value = 'HCS';
	}

	return (
		(parser &&
			parser({
				departmentName,
				value,
				record: data,
			})) ||
		'No Data'
	);
}

function getValueFromObject(obj, string) {
	/*
		Access props with mongo syntax, use brackets to find by attribute 

		string = "stages.[type='Exclusions'].sites.[name='oig'].result.firstName" 
		string = "stages.[type='Exclusions'].state" 
		string = "status.name" 
	*/
	const regex = /(\w+)(?:\.\[(\w+)='([^']+)'\])?(?:\.(.*))?/;
	const matches = string.match(regex);

	if (matches && matches.length >= 2) {
		const propertyName = matches[1];
		const arrayPropertyName = matches[2];
		const arrayPropertyValue = matches[3];
		const remainingString = matches[4];

		if (arrayPropertyName) {
			const array = obj[propertyName];
			if (array && Array.isArray(array)) {
				const item = array.find(item => item[arrayPropertyName] === arrayPropertyValue);
				if (item) {
					if (!remainingString) {
						return item;
					} else {
						return getValueFromObject(item, remainingString);
					}
				}
			}
		} else {
			const value = obj[propertyName];
			if (!remainingString) {
				return value;
			} else if (value && typeof value === 'object') {
				return getValueFromObject(value, remainingString);
			}
		}
	}

	return undefined;
}

function renderTableView(tableStructure, departmentName) {
	const columns = [];
	const types = tableStructure.__types;

	for (const key in tableStructure) {
		if (key === '__types') continue;

		let parserName = types && types[key];

		let path = (tableStructure && tableStructure[key]) || '';

		let className = '';
		if (tableStructure[key] === 'ticketID' || tableStructure[key] === 'entityID') {
			className = 'ticket-id-column';
		} else if (tableStructure[key] === 'source' || tableStructure[key] === 'company') {
			className = 'ticket-capitalize-first-letter';
		}

		columns.push({
			title: <span className="ticket-table-titles">{key}</span>,
			dataIndex: tableStructure[key],
			render: (text, record, index) => {
				const displayText = renderData(record, {
					path,
					parserName,
					departmentName,
				});
				return typeof displayText === 'string' ? <span>{displayText}</span> : displayText;
			},
			className,
		});
	}

	return columns;
}

const entityAliasMapper = {
	matched: 'caregiver',
	accepted: 'caregiver',
	unanswered: 'caregiver',
};

const getCommsMetaInfo = (obj, searchKeys = { phoneKey: 'primaryPhone', langKey: 'language' }, infoArr = []) => {
	const { phoneKey, langKey } = searchKeys;

	const pathInfo = {};

	for (const key in obj) {
		const value = obj[key];

		if (!Array.isArray(value) && typeof value === 'object') {
			infoArr = getCommsMetaInfo(value, searchKeys, infoArr);
		} else if (typeof value === 'string') {
			const pathArr = value.split('.');

			const destKey = pathArr[pathArr.length - 1];

			if (destKey === phoneKey) {
				const entity = phoneKey !== pathArr[0] ? entityAliasMapper[pathArr[0]] || pathArr[0] : undefined;

				pathInfo['phonePath'] = value;

				if (entity) pathInfo['entity'] = entity;
			} else if (destKey === langKey) {
				pathInfo['langPath'] = value;
			}
		}
	}

	if (pathInfo.phonePath) infoArr.push(pathInfo);

	return infoArr;
	// e.g. [{ entity: 'name', 'phonePath': entity.primaryPhone, langPath: entity.language }]
};

const getCallTitle = ticket => {
	let entity;
	let title = '';
	if (ticket) {
		if (ticket.title) {
			title = ticket.title;
		} else if (ticket.firstName || ticket.lastName) {
			title = `${ticket.firstName} ${ticket.lastName}`.trim();
		} else if (ticket.lead) {
			entity = ticket.lead;
		} else if (ticket.student) {
			entity = ticket.student;
		} else if (ticket.caregiver) {
			entity = ticket.caregiver;
		} else if (ticket.applicant) {
			entity = ticket.applicant;
		} else if (ticket.entity) {
			entity = ticket.entity;
		}

		if (!title && entity) {
			const { firstName, lastName } = entity;

			title = `${firstName} ${lastName}`.replace('undefined');
		}
	}

	if (!title.trim().length) return null;

	title = title
		.split(' ')
		.map(word => word[0].toUpperCase() + word.slice(1))
		.join(' ')
		.trim();

	if (title.length > 20) title = title.slice(0, 17) + '...';

	return title;
};

export { parserMapper, dataParser, renderTableView, getCallTitle, getCommsMetaInfo };
