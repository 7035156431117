import queryString from 'query-string';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { flattenObject } from './utils';
import request from '../../../utils/API/apiHandler';
import { socket } from '../../../utils/socketHandler';
import { formatPhoneNumberForSearch } from '../../../utils/functions';

const initialState = {
	disableNext: false,
	allTickets: [],
	loading: false,
	error: false,
	selectedTickets: [],
	tableListStructure: {},
	messageTemplates: [],
	trainingTypes: [],
	page: 1,
	pageSize: 10,
	ticketsTotalNum: null,
	metaData: null,
	ticketsToCall: [],
	searchKey: '',
	isFiltered: false,
	showBGCheck: false,
};

export const getAllTickets = createAsyncThunk('tickets/get', async ({ params }, { getState, signal }) => {
	const { page, pageSize, showBGCheck } = getState().allTicketsReducer;

	const { departmentName } = params;
	const { selectedFilters } = getState().departmentFilters;

	let filters = selectedFilters;

	if (Object.keys(filters).some(x => x !== 'createdAt' && (!!filters[x]?.from || !!filters[x]?.to)) && filters?.createdAt) {
		filters = { ...filters, createdAt: undefined };
	}

	const { activeFilter } = getState().departmentHeader;
	let queryParams = { page: page - 1, pageSize, ...activeFilter };
	if (showBGCheck) queryParams.showBGCheck = showBGCheck;

	if (Object.keys(filters).length) {
		queryParams = {
			...queryParams,
			...filters,
		};
	}

	const data = queryString.stringify(flattenObject(queryParams), { arrayFormat: 'bracket' });

	let param = departmentName === 'caregiver' || departmentName === 'patient' ? 'entities' : 'tickets';

	const responseData = await request({ url: `/${param}/${departmentName}/?${data ? data : ''}`, zeroState: [], signal });

	return {
		data: responseData || { tickets: [], total: 0 },
		isFilter: Object.keys(selectedFilters).length !== 0,
	};
});

export const getMessageTemplates = createAsyncThunk('message-templates/get', async ({ params }, { getState }) => {
	const { departmentName } = params;
	return await request({ url: `/messages/bulk/${departmentName}` });
});

export const searchTickets = createAsyncThunk(`/tickets/search`, async ({ params, value }, { getState }) => {
	const { departmentName } = params;
	const { page, pageSize } = getState().allTicketsReducer;
	let param = departmentName === 'caregiver' || departmentName === 'patient' ? 'entities' : 'tickets';
	value = formatPhoneNumberForSearch(value);

	return {
		data: await request({
			method: 'post',
			url: `/${param}/${departmentName}/search`,
			data: JSON.stringify({
				query: value,
				page,
				pageSize,
			}),
		}),
		searchKey: value,
		fallbackData: [],
	};
});

const allTickets = createSlice({
	name: 'allTickets',
	initialState,

	reducers: {
		updateFilter: (state, { payload }) => {
			state.selectedFilters = { ...state.selectedFilters, ...payload };
		},
		updateSelectedTickets: (state, { payload }) => {
			state.selectedTickets = payload;
		},
		setPage: (state, { payload }) => {
			state.page = payload;
		},
		setPageSize: (state, { payload }) => {
			state.pageSize = payload;
		},
		clearTickets: state => {
			state.allTickets = null;
		},
		search: (state, { payload }) => {
			const value = formatPhoneNumberForSearch(payload?.value);
			state.loading = true;
			state.allTickets = null;
			state.searchKey = value;
			state.progressive = { loading: true };
			socket.emit('service:run', 'Search', 'tickets', { ...payload, value });
		},
		setDisableNext: (state, { payload }) => {
			state.disableNext = payload;
		},
		loadMore: state => {
			if (!state?.progressive?.next?.department || state.searchKey !== state?.progressive?.next?.value) {
				return;
			}
			socket.emit('service:run', 'Search', 'tickets', { ...state?.progressive?.next });
			state.progressive.loading = true;
		},
		applyProgressiveResult: (state, { payload }) => {
			if (!state.searchKey || (payload?.next && payload?.next?.value !== state.searchKey)) {
				return;
			}
			state.error = false;
			state.searchKey = payload?.next?.value || state.searchKey;
			state.progressive = { ...payload, data: undefined };
			state.loading = false;
			if (!state.allTickets?.length) {
				state.allTickets = payload.data;
			} else {
				state.allTickets.push(...payload.data);
			}
			state.ticketsTotalNum = state.allTickets.length;
		},
		setShowBGCheck: (state, { payload }) => {
			state.showBGCheck = !payload;
		},
	},
	extraReducers: {
		[getAllTickets.rejected]: (state, { error }) => {
			state.loading = false;
			state.error = error;
		},
		[getAllTickets.fulfilled]: (state, { payload }) => {
			state.error = false;
			state.loading = false;
			state.allTickets = payload.data
				? payload.data.tickets
					? payload.data.tickets
					: payload.data.entities
				: payload.tickets
				? payload.tickets
				: payload.entities;
			state.ticketsTotalNum = payload.data ? payload.data.total : payload.total;
			state.tableListStructure = payload.data?.tableStructure ? payload.data?.tableStructure : {};
			state.selectedTickets = [];
			state.searchKey = '';
			state.isFiltered = payload.isFilter;
		},
		[getAllTickets.pending]: state => {
			state.loading = true;
			state.allTickets = null;
			state.searchKey = '';
			state.progressive = null;
			socket.emit('service:run', 'Search', 'cancelSearch');
		},
		[getMessageTemplates.rejected]: (state, { error }) => {
			state.loading = false;
			state.error = error;
		},
		[getMessageTemplates.fulfilled]: (state, { payload }) => {
			state.error = false;
			state.loading = false;
			state.messageTemplates = payload;
		},
		[getMessageTemplates.pending]: state => {
			state.loading = true;
			state.messageTemplates = [];
			state.searchKey = '';
			state.progressive = null;
			socket.emit('service:run', 'Search', 'cancelSearch');
		},
		[searchTickets.rejected]: (state, { error }) => {
			state.loading = false;
			state.error = error;
		},
		[searchTickets.pending]: (state, action) => {
			state.loading = true;
			state.allTickets = null;
			state.searchKey = '';
			state.progressive = null;
			socket.emit('service:run', 'Search', 'cancelSearch');
		},
		[searchTickets.fulfilled]: (state, { payload }) => {
			state.error = false;
			state.loading = false;
			const allTickets = payload.data;
			state.allTickets = allTickets;
			state.ticketsTotalNum = allTickets.length;
			state.searchKey = payload.searchKey;
		},
	},
});

export const { reducer, actions, state } = allTickets;
export const { clearTickets } = allTickets.actions;
