import axios from 'axios';
import { API_GATEWAY } from '../../config';
import { notification } from 'antd';
import { handleUnauthenticated } from './interceptors';
import get from 'lodash/get';

axios.interceptors.response.use(res => res, handleUnauthenticated);

export function createHeaders(isUpload = false, isAuth = true, opts = {}) {
	const headers = { ...opts };
	if (isAuth) headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
	headers['Content-Type'] = isUpload ? 'multipart/form-data' : 'application/json';
	return headers;
}

async function request(options) {
	const {
		api = 'mainAPI',
		url = '',
		method = 'get',
		data = {},
		params = {},
		fallbackData,
		errorCallback,
		notify,
		responseType,
		timeoutOverride,
		onSuccess,
		returnError = false,
		throwError = false,
		signal,
	} = options;

	const timeout = timeoutOverride ? timeoutOverride : api === 'uploadMainAPI' || responseType === 'blob' ? 50000 : 30000;

	const API_URLS = {
		...API_GATEWAY,
		uploadMainAPI: API_GATEWAY.mainAPI,
	};

	try {
		const result = await axios({
			baseURL: API_URLS[api],
			headers: createHeaders(api === 'uploadMainAPI'),
			timeout,
			url,
			method,
			data,
			params,
			...(responseType && { responseType }),
			signal,
		});
		onSuccess?.(result.data);
		return result.data;
	} catch (error) {
		const message = get(error, 'response.data.message', 'An error has occurred');

		if (notify === true) {
			notification.error({ message, key: 'requestErrorMessage' });
		} else if (notify) {
			notification.error({ message: notify, key: 'requestErrorMessage' });
		}

		await errorCallback?.(error, message);

		if (throwError) {
			throw new Error(error);
		}

		if (returnError) {
			const formattedError = { status: error?.response?.status, message: error?.response?.data?.status, error: error?.response?.data?.message };
			return formattedError;
		}

		return fallbackData;
	}
}

export default request;
