export const STAGES = {
	ApplicationTab: 'Application',
	DocumentsTab: 'Documents',
	VerificationsTab: 'Verifications',
	RegistryTab: 'Registry',
	I9Tab: 'I9',
	'E-VerifyTab': 'EVerify',
	OrientationTab: 'Orientation',
	ExclusionsTab: 'Exclusions',
	'Employment VerificationTab': 'EmploymentVerification',
	'Background CheckTab': 'BGCheck',
	EMRTab: 'EMR',
	'Pay RateTab': 'PayRate',
	'License VerificationTab': 'LicenseVerification',
	'HR SignatureTab': 'Signature',
	'RN SignatureTab': 'RnSignature',
	ApprovalTab: 'Approval',
	'Virtual MeetingTab': 'VirtualMeeting',
	'Coordinator Signature': 'Coordinator Signature',
	ConversationTab: 'Conversation',
};
